import axios from "axios";
import store from "@/store/index.js";

const regURLBase = "api/theater/contract/:code:/data";

const dataURLRegExp = new RegExp(
  "^" + regURLBase.replace(":code:", "\\w+") + "$"
);

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_URL,
  withCredentials: false,

  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

apiClient.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

function pushNotification(error) {
  // this can happen if you have a network error and
  // there is no response

  if (!error.response) {
    return true;
  }

  if (!error.response.config) {
    return true;
  }

  return !error.response.config.ignoreError;
}

apiClient.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    if (error.response.status === 404) {
      if (dataURLRegExp.test(error.config.url)) {
        throw new Error("INVALID_CODE");
      }
    }

    if (pushNotification(error)) {
      const notification = {
        type: "error",
        message: error.message,
        details: error.data,
        error: error,
        timeout: 60000
      };

      store.dispatch("notification/add", notification, { root: true });
    }

    return Promise.reject(error);
  }
);

function getDataURL(code) {
  return regURLBase.replace(":code:", code);
}

export default {
  throw404() {
    return apiClient.get("/api/pingsx", { params: { a: 3, b: 5 } });
  },

  getContractData(code) {
    return apiClient.get(getDataURL(code));
  },

  updateContractData(code, data) {
    return apiClient.put(getDataURL(code), data);
  },

  getContract(code) {
    return apiClient.get(`api/theater/contract/${code}`);
  },

  getContractVerification(code) {
    return apiClient.get(`api/theater/contract/${code}/verification`);
  }
};
