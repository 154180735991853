import api from "@/services/api";

export const namespaced = true;

export const defaultReg = Object.freeze({
  payee: {
    dba: null,
    zip: null,
    city: null,
    state: null,
    house_number: null,
    street: null,
    payee_id: null,
    street_2: null,
    payee_name: null,
    buying_subgroup_id: null,
    theater_contract_id: null,
    contract_year: null,
    tax_id: null,
    bank_name: null,
    bank_routing_number: null,
    bank_account_number: null,
    bank_account_holder_name: null
  },
  warehouses: []
});

export const state = {
  loading: false,
  registration: Object.assign({}, defaultReg),
  notFound: false,
  status: null
};

export const mutations = {
  SET(state, reg) {
    state.registration = reg;
  },

  SET_LOADING(state, value) {
    state.loading = value;
  },

  SET_NOT_FOUND(state, value) {
    state.notFound = value;
  },

  SET_STATUS(state, value) {
    state.status = value;
  },

  CLEAR(state) {
    state.registration = Object.assign({}, defaultReg);
  }
};

export const actions = {
  getRegistrationData(context, regCode) {
    context.commit("SET_LOADING", true);
    context.commit("SET_NOT_FOUND", false);

    api
      .getContractData(regCode)
      .then(response => {
        if (response.data.rows) {
          const row = response.data.rows[0];

          if (row?.status) {
            context.commit("SET_STATUS", row);
          } else {
            const reg = row;

            if (!reg.warehouses || !Array.isArray(reg.warehouses)) {
              reg.warehouses = [];
            }

            reg.warehouses.forEach((item, i) => {
              item.subID = i;
            });

            context.commit("SET", reg);
          }
        }
        context.commit("SET_LOADING", false);
      })
      .catch(e => {
        context.commit("SET_LOADING", false);

        if (e.message === "INVALID_CODE") {
          context.commit("SET_NOT_FOUND", true);
        }
      });
  }
};

export const getters = {
  isLoaded() {
    return !!state.registration.payee_id;
  }
};
