<template>
  <!-- <v-container> -->
  <div>
    <NotificationBar
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
  <!-- </v-container> -->
</template>

<script>
import NotificationBar from "@/components/utils/NotificationBar.vue";
import { mapState } from "vuex";

export default {
  components: {
    NotificationBar
  },
  computed: mapState("notification", ["notifications"])
};
</script>

<style scoped></style>
