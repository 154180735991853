<template>
  <v-app>
    <div>
      <v-app-bar color="primary" dark>
        <v-img contain max-width="60" max-height="39" :src="mwcLogo"></v-img>
        <v-toolbar-title>Mars Wrigley Rebate Program</v-toolbar-title>
      </v-app-bar>
    </div>

    <v-main>
      <NotificationContainer />

      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import NotificationContainer from "@/components/utils/NotificationContainer.vue";

export default {
  name: "App",

  components: {
    NotificationContainer
  },

  data() {
    return {
      mwcLogo: require("@/assets/mwc_logo.png")
    };
  },
  methods: {}
};
</script>

<style>
.banner {
  background-color: #1976d2;
  height: 80px;
  width: 100%;
  color: white;
  line-height: 80px;
  display: inline-block !important;
  font-size: 2rem;
}

/*** TRANSITIONS ***/
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter {
  transform: translateX(30px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-to {
  transform: translateX(-30px);
  opacity: 0;
}
</style>
