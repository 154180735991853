import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },

    {
      path: "/registration/",
      name: "Registration",
      component: () => import("../views/Registration.vue")
    },

    {
      path: "/registration/:code",
      name: "TheaterContract",
      component: () => import("../views/TheaterContract.vue")
    },

    {
      path: "/registration/:code/completed",
      name: "Completed",
      component: () => import("../views/Completed.vue")
    },

    {
      path: "/registration/:code/pending",
      name: "Pending",
      component: () => import("../views/Pending.vue")
    },

    {
      path: "/registration/:code/expired",
      name: "Expired",
      component: () => import("../views/Expired.vue")
    },

    {
      path: "/theater/contract/:code/verified",
      name: "ContractVerified",
      component: () => import("../views/ContractVerified.vue")
    },

    {
      path: "/404",
      name: "404",
      component: NotFound
    },

    {
      // Here's the new catch all route
      path: "*",
      redirect: { name: "404" }
    }
  ]
});

export default router;
