import Vue from "vue";
// import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import VModal from "vue-js-modal";
import "prismjs";
import "prismjs/themes/prism.css";

Vue.use(Vuelidate);

Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
  dynamicDefaults: { clickToClose: true }
});

Vue.prototype.$eventHub = new Vue();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,

  created() {
    this.$vuetify.theme.dark = false;
  },
  render: h => h(App)
}).$mount("#app");
