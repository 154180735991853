<template>
  <transition name="slide-fade" mode="out-in">
    <v-alert
      prominent
      v-bind:type="notification.type"
      v-model="display"
      dismissible
    >
      <v-row>
        <v-col class="grow">
          <v-expansion-panels accordion flat v-bind:disabled="!hasDetails">
            <v-expansion-panel>
              <v-expansion-panel-header v-bind:class="notificationTypeClass">
                <h3>{{ getDisplayMsg }}</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-bind:class="notificationTypeClass">
                {{ notification.details }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-col class="shrink">
          <v-btn
            v-if="notification.type === 'error'"
            text
            class="mt-2"
            @click="copy()"
            ><v-icon>mdi-content-copy</v-icon>Copy to clipboard
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </transition>
</template>

<script>
import { mapActions } from "vuex";
import { objectToKeyValues } from "@/lib/utils";

export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      timeout: null,
      display: true
    };
  },

  methods: {
    ...mapActions("notification", ["remove"]),

    isObject(obj) {
      return (
        obj === Object(obj) &&
        Object.prototype.toString.call(obj) !== "[object Array]"
      );
    },

    isString(value) {
      return typeof value === "string" || value instanceof String;
    },

    copy() {
      let msg = "";
      let n = this.notification;

      if (n.message) {
        msg = msg + "message: " + this.notification.message + "\n";
      }

      if (n.details) {
        msg = msg + "details: " + this.notification.details + "\n";
      } else {
        msg = msg + "details: none \n";
      }

      if (n.error) {
        msg = msg + "error:\n";

        let e = n.error;

        if (e.response && e.response.config) {
          msg =
            msg + "\t response.data: " + JSON.stringify(e.response.data) + "\n";

          let config = e.response.config;

          msg = msg + "\t header/accept: " + config.headers.Accept + "\n";

          if (config.headers.Authorization) {
            msg = msg + "\t header/Authorization: Yes" + "\n";
          } else {
            msg = msg + "\t header/Authorization: No" + "\n";
          }

          msg = msg + "\t method: " + config.method + "\n";
          msg = msg + "\t baseURL: " + config.baseURL + "\n";
          msg = msg + "\t url: " + config.url + "\n";

          if (config.params) {
            msg = msg + "\t params: " + objectToKeyValues(config.params) + "\n";
          } else {
            msg = msg + "\t params: none\n";
          }

          if (config.data) {
            msg =
              msg +
              "\t payload data: " +
              objectToKeyValues(JSON.parse(config.data)) +
              "\n";
          } else {
            msg = msg + "\t data: none\n";
          }
        }
      }

      navigator.clipboard
        .writeText(msg)
        .then(() => {})
        .catch(e => {
          alert("Copy failed: " + e);
        });
    }
  },

  mounted() {
    this.timeout = setTimeout(
      () => this.remove(this.notification),
      this.notification.timeout || 3000
    );
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  watch: {
    display(newValue) {
      if (!newValue) {
        this.remove(this.notification);
      }
    }
  },

  computed: {
    notificationTypeClass() {
      return this.notification.type + " white--text pa-0";
    },

    hasDetails() {
      return this.notification.details && this.notification.details !== "";
    },

    getDisplayMsg() {
      let msg = this.notification.message;

      if (this.notification.error) {
        let e = this.notification.error;
        if (e.response && e.response.data) {
          let d = e.response.data;
          if (this.isObject(d)) {
            let x = d.message || d.details || "Unknown";

            if (this.isString(x)) {
              return `${msg} (${x})`;
            }
          } else {
            return `${msg} (${d})`;
          }
        }
      }

      return msg;
    }
  }
};
</script>

<style scoped></style>
