<template>
  <div class="home"></div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      url: process.env.VUE_APP_URL,
      title: process.env.VUE_APP_TITLE
    };
  },

  mounted() {
    this.$router.push({ name: "Registration" });
  }
};
</script>
